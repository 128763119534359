<template>
  <div class="shipments-page">
    <div class="flex items-center justify-between">
      <h2>{{$t('Pallet')}}</h2>
      <vs-button @click="openPalletModal()" v-if="$store.state.AppActiveUser.userRole === 'fc-operations-supervisor'" color="primary" icon-pack="feather" icon="icon-plus" size="large"></vs-button>
    </div>
    <shipblu-table
        :sst="true"
        multiple
        v-model="selected"
        pagination
        :max-items="maximumItems"
        @search="handleSearch"
        @keyup.enter.native="handleSearch"
        search
        :data="pallets"
        :tableLoader="tableLoader"
      >
      <template slot="thead">
        <shipblu-th>{{$t('ID')}}</shipblu-th>
        <shipblu-th>{{$t('Aisle')}}</shipblu-th>
        <shipblu-th>{{$t('Capacity')}}</shipblu-th>
        <shipblu-th>{{$t('No. Products')}}</shipblu-th>
        <shipblu-th>{{$t('Actions')}}</shipblu-th>
      </template>

      <template slot-scope="{ data }">
        <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <shipblu-td :data="data[indextr].id">
            {{ data[indextr].ops_id }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].storage_location">
            {{ data[indextr].storage_location ? data[indextr].storage_location.aisle : 'N/A' }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].capacity">
            {{ data[indextr].capacity }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].numberOfPackages">
            {{ data[indextr].number_of_products }}
          </shipblu-td>

          <shipblu-td>
              <vs-dropdown
                vs-trigger-click
                class="dd-actions cursor-pointer"
                @click="selected = []"
              >
                <vs-button
                  type="border"
                  size="small"
                  icon-pack="feather"
                  icon="icon-more-horizontal"
                  class="mr-2"
                >
                </vs-button>
                <vs-dropdown-menu style="width: 190px">
                  <vs-dropdown-item
                    @click="printQrCode(data[indextr])"
                  >
                    <span class="flex items-center">
                      <feather-icon
                        icon="PrinterIcon"
                        svgClasses="h-4 w-4"
                        class="mr-2"
                      />
                      <span>{{$t('Print QR Code')}}</span>
                    </span>
                  </vs-dropdown-item>
                </vs-dropdown-menu>
              </vs-dropdown>
             </shipblu-td>
        </shipblu-tr>
      </template>
    </shipblu-table>
    <shipblu-pagination :maximumItems="maximumItems" :noOptions="true" :totalRows="totalRows" :currentPage="currentPage" @currentPage="currentPage = $event" />
    <qr-code-storage-units :storageUnit="storageUnit"></qr-code-storage-units>
    <add-storage-unit v-if="$store.state.AppActiveUser.userRole === 'fc-operations-supervisor'" type = "pallet" :storageModal="storageModal" @storageModal="storageModal = $event" @loadData="loadPallets"></add-storage-unit>
  </div>
</template>

<script>
import ShipbluTable from '../../layouts/components/NewShipBluTable.vue'
import ShipbluTr from '../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../layouts/components/ShipBluTd.vue'
import AddStorageUnit from './components/AddStorageUnit.vue'
import {sendFulfillmentRequest} from '../../http/axios/requestHelper.js'
import common  from '@/assets/utils/common'
import QrCodeStorageUnits from './components/QrCodeStorageUnits.vue'
import ShipbluPagination from '../../layouts/components/ShipbluPagination.vue'

export default {
  components: {
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    AddStorageUnit,
    QrCodeStorageUnits,
    ShipbluPagination
  },
  data () {
    return {
      maximumItems: process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      totalRows: 0,
      selected: [],
      currentPage: (this.$route.query.page &&  Number(this.$route.query.page) > 0) ? Number(this.$route.query.page) : 1,
      searchVal: '',
      offset: 0,
      pallets:[],
      searchInProgress: false,
      searchedValue: ' ',
      storageModal: false,
      tableLoader: false,
      storageUnit: {}
    }
  },
  methods: {
    openPalletModal () {
      this.storageModal = true
    },
    loadPallets () {
      this.searchInProgress = true
      this.tableLoader = true
      const query = `?offset=${this.offset}&search=${this.searchVal}&limit=${this.maximumItems}`
      sendFulfillmentRequest(true, false, this, `api/v1/fc/pallets/${query}`, 'get', null, true,
        (response) => {
          this.pallets = response.data.results
          this.totalRows = response.data.count
          this.searchedValue = this.searchVal
          this.tableLoader = false
        }
      )
      this.searchInProgress = false
    },
    removeAttribute (event) {
      event.preventDefault()
    },
    handleSearch (search) {
      this.offset = 0
      this.currentPage = 1
      this.searchVal = search.target ? search.target.value : search
      return common.manageSearch(search, this.loadPalletsSearch)
    },
    loadPalletsSearch () {
      if (this.searchInProgress || this.searchedValue === this.searchVal) {
        return
      }
      this.loadPallets()
    },
    printQrCode (storage) {
      this.storageUnit = storage
      setTimeout(() => {
        window.print()
      }, 300)
    }
  },
  watch: {
    currentPage () {
      this.offset = (this.currentPage - 1) * this.maximumItems
      this.$router.push({
        query: {
          page: this.currentPage
        }
      }).catch(() => {})
      this.loadPallets()
    }
  },
  mounted () {
    this.$emit('setAppClasses', 'shipment-page')
  },
  created () {
    this.offset = (this.currentPage - 1) * this.maximumItems
    this.loadPallets()
  }
}
</script>
<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
#order-label-print{
  display: none;
  visibility: hidden;
}
.con-pagination-table{
  display: none;
}
</style>